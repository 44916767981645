import { useDisclosure } from "@chakra-ui/hooks";
import { Text, Alert as ChakraAlert, AlertProps, CloseButton, AlertDescription, AlertTitle, Box } from "@chakra-ui/react";
import { HiCheckCircle, HiExclamationCircle, HiInformationCircle } from "react-icons/hi2";

export type AlertCompProps = AlertProps & {
  status: AlertProps["status"];
  title?: AlertProps["title"];
  description: string;
  close?: boolean;
};

// MAIN COMPONENT
export default function Alert({ status, title, description, close, size = "md", ...props }: AlertCompProps) {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  return isOpen ? (
    <ChakraAlert status={status} rounded="md" alignItems="flex-start" {...props}>
      <AlertIcon status={status} />
      <Box className="alert-text-wrapper" flex="1">
        {title && <AlertTitle fontSize={size}>{title}</AlertTitle>}
        <AlertDescription fontSize={size}>
          <Text lineHeight="base">{description}</Text>
        </AlertDescription>
      </Box>
      {close && <CloseButton onClick={onClose} size="sm" alignSelf="flex-start" position="relative" right={-2} />}
    </ChakraAlert>
  ) : null;
}

// SUB-COMPONENTS
export const AlertIcon = ({ status }: { status: AlertCompProps["status"] }) => {
  const alertIconStyles = {
    style: {
      marginRight: "0.5rem",
      width: "1.5rem",
      height: "1.5rem",
    },
  };
  switch (status) {
    case "error":
      return (
        <Box {...{ color: "red.500" }}>
          <HiExclamationCircle {...alertIconStyles} />
        </Box>
      );
    case "success":
      return (
        <Box {...{ color: "green.500" }}>
          <HiCheckCircle {...alertIconStyles} />
        </Box>
      );
    case "warning":
      return (
        <Box {...{ color: "yellow.500" }}>
          <HiExclamationCircle {...alertIconStyles} />
        </Box>
      );
    default:
      return (
        <Box {...{ color: "blue.500" }}>
          <HiInformationCircle {...alertIconStyles} />
        </Box>
      );
  }
};
